import { Box, Typography } from "@mui/material";
import React from "react";
import "../style/Footer.css";


const Footer = () => {
    return (
        <Box className="footer" position="Fixed">
            <Typography> Copywrite by Satya Dhandhukiya © // 2023 </Typography>
        </Box >
    )


}

export default Footer; 